import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {Formik} from 'formik';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {FileUpload} from 'primereact/fileupload';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {I18n, Translate} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Dropdown} from "primereact/dropdown";
import {Order} from "../helpers/order";
import {connect} from "react-redux";
import * as Yup from 'yup';
import {Editor} from 'primereact/editor';
import AppConditionService from "../services/AppConditionService";
import {getFormErrorMessage, isFormFieldInvalid} from "../helpers/utils";

class AppCondition extends Component {
    emptyElement = {
        name: '', type: '', countryCode: '', content: ''
    };

    constructor(props) {
        super(props);

        this.formikProps = {
            initialValues: this.emptyElement, validationSchema: Yup.object().shape({
                name: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                type: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                countryCode: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                content: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
            }), onSubmit: (element) => {
                this.saveElement(element)
            }
        };

        this.state = {
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            deleteElementDialog: false,
            deleteElementsDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.elementService = new AppConditionService();
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelectedElements = this.deleteSelectedElements.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
    }

    componentDidMount() {
        this.createOptions();
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue).then(response => {
            this.setState({
                elements: response.data.content.list ? response.data.content.list : [],
                totalRecords: response.data.content.totalCount,
                loading: false
            })
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
        }
    }

    createOptions() {
        const menuModel = [{
            label: I18n.t('EDIT_DATA'),
            icon: 'pi pi-fw pi-pencil',
            command: () => this.editElement(this.state.selectedElement)
        }, {
            label: I18n.t('DELETE_DATA'),
            icon: 'pi pi-fw pi-trash',
            command: () => this.confirmDeleteElement(this.state.selectedElement)
        }];

        const sortFilterValues = [{
            name: I18n.t('TITLE') + ' ' + I18n.t('ASCENDING'),
            field: 'pseudo',
            order: Order.ASC
        }, {name: I18n.t('TITLE') + ' ' + I18n.t('DESCENDING'), field: 'pseudo', order: Order.DESC}];
        this.setState(({
            menuModel, sortFilterValues
        }));
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        this.elementService.getPage(page, rows, this.state.sortFilterValue, this.state.searchFilterValue).then(response => {
            this.setState({
                first,
                elements: response.data.content.list ? response.data.content.list : [],
                totalRecords: response.data.content.totalCount,
                loading: false
            })
        });
    }

    openNew() {
        this.setState({
            elementDialog: true
        });
    }

    hideDialog() {
        this.formikProps.initialValues = this.emptyElement;
        this.setState({
            elementDialog: false, element: this.emptyElement
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    saveElement(element) {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        if (element.id) { //may be update
            this.elementService.edit(element.id, element)
                .then((response) => {
                    const index = this.findIndexById(element.id);
                    elements[index] = response.data.content;
                    this.setState({
                        elements, elementDialog: false, loadingDialog: false, element: this.emptyElement,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({
                        severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        } else { //may be creation
            this.elementService.add(element)
                .then((response) => {
                    elements.unshift(response.data.content);
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                        totalRecords: this.state.totalRecords + 1,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({
                        severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000
                    });
                });
        }
    }

    editElement(element) {
        this.formikProps.initialValues = element;
        this.setState({
            elementDialog: true
        });
    }

    confirmDeleteElement(element) {
        this.setState({
            element, deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(() => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                let selectedElements = this.state.selectedElements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords - 1,
                    loadingDialog: false,
                    selectedElements
                });
                this.toast.show({
                    severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000
                });
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false, element: this.emptyElement, loadingDialog: false
                });
                this.toast.show({
                    severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000
                });
            });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    deleteSelectedElements() {
        this.setState({
            loadingDialog: true,
        });
        let dataIds = this.state.selectedElements.map((el) => el.id);
        this.elementService.deleteMany(dataIds)
            .then(() => {
                let elements = this.state.elements.filter(val => !this.state.selectedElements.includes(val));
                this.setState({
                    totalRecords: this.state.totalRecords - this.state.selectedElements.length,
                    elements,
                    deleteElementsDialog: false,
                    selectedElements: [],
                    loadingDialog: false,
                });
                this.toast.show({
                    severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000
                });
            })
            .catch(error => {
                this.setState({
                    deleteElementsDialog: false, loadingDialog: false
                });
                this.toast.show({
                    severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000
                });
            });
    }

    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue, searchFilterValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({
                    severity: 'error', summary: I18n.t('SEARCH'), detail: I18n.t('SEARCH_NOT_IMPLEMENTED'), life: 6000
                });
            });
    }

    leftToolbarTemplate() {
        return (<React.Fragment>
                <Button label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2"
                        onClick={this.openNew}/>
                <Button label={I18n.t('DELETE_DATA')} icon="pi pi-trash" className="p-button-danger"
                        onClick={this.confirmDeleteSelected} disabled={!this.state.selectedElements.length}/>
            </React.Fragment>)
    }

    rightToolbarTemplate() {
        return (<React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label={I18n.t('IMPORT_DATA')}
                            chooseLabel="Import" className="p-mr-2 p-d-inline-block"/>
                <Button label={I18n.t('EXPORT_DATA')} icon="pi pi-upload" className="p-button-help"
                        onClick={this.exportCSV}/>
            </React.Fragment>)
    }

    render() {
        const header = (<div className="table-header">
                <h5 className="p-m-0">{I18n.t('APP_CONDITIONS')}</h5>
                <span className="p-input-icon-left">
    <i className="pi pi-search"/>
    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue}
               onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')} placeholder={I18n.t('SEARCH_DATA')}/>
 <Dropdown style={{width: '200px', marginLeft: '7px'}} value={this.state.sortFilterValue}
           onChange={(e) => this.onGlobalFilterChange(e, 'sortFilter')}
           options={this.state.sortFilterValues} optionLabel="name" placeholder={I18n.t('SELECT_ORDER')}/>
</span>
            </div>);
        const deleteElementDialogFooter = (<React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times"
                        className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check"
                        className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>);
        const deleteElementsDialogFooter = (<React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times"
                        className="p-button-text" onClick={this.hideDeleteElementsDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check"
                        className="p-button-text" onClick={this.deleteSelectedElements}/>
            </React.Fragment>);

        const actionBodyTemplate = (rowData) => {
            return (<React.Fragment>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2"
                            onClick={() => this.editElement(rowData)}/>
                </React.Fragment>);
        }

        return (<div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <ContextMenu model={this.state.menuModel} ref={el => this.cm = el}
                             onHide={() => this.setState({selectedElement: null})}/>
                <div className="card">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}/>
                    <DataTable ref={(el) => this.dt = el} value={this.state.elements}
                               selection={this.state.selectedElements}
                               onSelectionChange={(e) => this.setState({selectedElements: e.value})}
                               dataKey="id" paginator lazy loading={this.state.loading} responsive
                               contextMenuSelection={this.state.selectedElement}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                               onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                               rows={this.state.pageSize} totalRecords={this.state.totalRecords}
                               first={this.state.first} onPage={this.onPage}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                               header={header}>
                        <Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>
                        <Column field="name" header={I18n.t('NAME')}/>
                        <Column field="type" header={I18n.t('TYPE')}/>
                        <Column field="countryCode" header={I18n.t('COUNTRY_CODE')}/>
                        <Column field="appVersion" header={I18n.t('VERSION')}/>
                        <Column headerStyle={{width: '3rem'}} body={actionBodyTemplate}/>
                    </DataTable>
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '800px'}} header={I18n.t('DETAILS')} modal
                        className="p-fluid" closable={false} onHide={this.hideDialog}>
                    <Formik {...this.formikProps} >
                        {props => {
                            console.log(props.values)
                            return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                         onSubmit={props.handleSubmit} className="p-fluid">
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        autoFocus
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        id="name"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'name')})}
                                    />
                                    {getFormErrorMessage(props, 'name')}
                                    <label htmlFor="name"
                                           className={classNames({'p-error': isFormFieldInvalid(props, 'name')})}>{I18n.t('NAME')}*</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="type"
                                        value={props.values.type}
                                        onChange={props.handleChange}
                                        id="type"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'type')})}
                                    />
                                    {getFormErrorMessage(props, 'type')}
                                    <label htmlFor="type"
                                           className={classNames({'p-error': isFormFieldInvalid(props, 'type')})}>{I18n.t('TYPE')}*</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="countryCode"
                                        value={props.values.countryCode}
                                        onChange={props.handleChange}
                                        id="countryCode"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'countryCode')})}
                                    />
                                    {getFormErrorMessage(props, 'countryCode')}
                                    <label htmlFor="countryCode"
                                           className={classNames({'p-error': isFormFieldInvalid(props, 'countryCode')})}>{I18n.t('COUNTRY_CODE')}*</label>
                                </div>

                                <div className="p-field" style={{marginTop: '10px'}}>
                                    <label htmlFor="content"
                                           className={classNames({'p-error': isFormFieldInvalid(props, 'content')})}>{I18n.t('CONTENT')}</label>
                                    <Editor
                                        name="content"
                                        id="content"
                                        style={{height: '400px'}}
                                        value={props.values.content}
                                        onTextChange={(e) => props.setFieldValue('content', e.htmlValue)}
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'content')})}
                                    />
                                    {getFormErrorMessage(props, 'content')}
                                </div>

                                <div className="p-dialog-footer">
                                    <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')}
                                            icon="pi pi-times" className="p-button-text p-button-help"
                                            onClick={this.hideDialog}/>
                                    <Button type="submit" loading={this.state.loadingDialog} label={I18n.t('SAVE')}
                                            icon="pi pi-check" className="p-button-text"/>
                                </div>
                            </form>
                        }}
                    </Formik>

                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}}
                        header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false}
                        onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element &&
                            <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.name}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementsDialog} style={{width: '450px'}}
                        header={I18n.t('CONFIRMATION')} modal footer={deleteElementsDialogFooter} closable={false}
                        onHide={this.hideDeleteElementsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.selectedElements.length && <Translate dangerousHTML value="DELETE_MANY_WARNING"
                                                                          count={this.state.selectedElements.length}/>}
                    </div>
                </Dialog>
            </div>);
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(AppCondition);


