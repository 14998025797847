import {LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, REGISTER_FAIL, REGISTER_SUCCESS, SET_MESSAGE, SET_PROFILE} from "./types";
import AuthService from "../services/AuthService";
import {Constant} from "../helpers/const";

export const register = (username, email, password) => (dispatch) => {
    return AuthService.register(username, email, password).then(
        (response) => {
            dispatch({
                type: REGISTER_SUCCESS,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.code,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.code) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};


export const login = (username, password, recaptcha) => (dispatch) => {
    return AuthService.login(username, password, recaptcha)
        .then(response => {
                const objectLogin = response.data
                console.log("login response ", objectLogin)
                if (objectLogin.code === 'LOGIN_SUCCESS') {
                    if (objectLogin.content.accessToken) {
                        localStorage.setItem(Constant.ACCOUNT, JSON.stringify(objectLogin.content));
                    }
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {account: objectLogin.content},
                    });
                }
                return dispatch(profile()).then(result => Promise.resolve(objectLogin))
            },
            (error) => {
                console.log("error login ", error.response.data)
                dispatch({
                    type: LOGIN_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: error.response.data.code,
                });

                return Promise.reject();
            }
        );
};

export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
        type: LOGOUT,
    });
    window.location.assign("/")
};

export const profile = () => (dispatch) => {
    return AuthService.getProfile()
        .then(response => {
                localStorage.setItem(Constant.USER_PROFILE, JSON.stringify(response.data.content));
                dispatch({
                    type: SET_PROFILE,
                    payload: response.data.content,
                });
                return Promise.resolve();
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.code) ||
                    error.message ||
                    error.toString();

                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });

                return Promise.reject();
            }
        );
};
