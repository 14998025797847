import HttpService from "./HttpService";

export default class AccountService extends HttpService {
    endPoint = "/api/v1/core/accounts"

    disableAccount(data) {
        return this.getAuthTokenRequest().post("/api/v1/core/auth/disableAccount", data);
    }

    enableAccount(data) {
        return this.getAuthTokenRequest().post("/api/v1/core/auth/enableAccount", data);
    }

    suspendAccount(data) {
        return this.getAuthTokenRequest().post("/api/v1/core/auth/suspendAccount", data);
    }
}
