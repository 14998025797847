import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import AccountService from "../services/AccountService";
import {I18n, Translate} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Order} from "../helpers/order";
import {connect} from "react-redux";

class Account extends Component {
    emptyElement = {
        id: null,
        name: '',
        acronym: '',
        description: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            disableElementDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            submitted: false,
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.elementService = new AccountService();

        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.suspendElement = this.suspendElement.bind(this);
        this.showSuspendElement = this.showSuspendElement.bind(this);
        this.confirmDisableElement = this.confirmDisableElement.bind(this);
        this.disableElement = this.disableElement.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDisableElementDialog = this.hideDisableElementDialog.bind(this);
        this.dateTemplate = this.dateTemplate.bind(this);
    }

    componentDidMount() {
        this.createOptions();
        this.setState({loading: true});
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order).then(
            response => {
                console.log('users response ', response.data.content.list)
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
        }
    }

    createOptions() {
        const menuModel = [
            {
                label: I18n.t('SUSPEND'),
                icon: 'pi pi-fw pi-pause',
                command: () => this.showSuspendElement(this.state.selectedElement)
            },
            {
                label: I18n.t('DISABLE_OR_ENABLE_DATA'),
                icon: 'pi pi-fw pi-check-circle',
                command: () => this.confirmDisableElement(this.state.selectedElement)
            }
        ];

        const sortFilterValues = [
            {name: I18n.t('NAME') + ' ' + I18n.t('ASCENDING'), field: 'name', order: Order.ASC},
            {name: I18n.t('NAME') + ' ' + I18n.t('DESCENDING'), field: 'name', order: Order.DESC},
            {name: I18n.t('ACRONYM') + ' ' + I18n.t('ASCENDING'), field: 'acronym', order: Order.ASC},
            {name: I18n.t('ACRONYM') + ' ' + I18n.t('DESCENDING'), field: 'acronym', order: Order.DESC}
        ];
        this.setState(({
            menuModel,
            sortFilterValues
        }));
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        let searchFilterValue = [{field: 'name', value: this.state.searchFilterValue}];
        this.elementService.getPage(page, rows, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order, searchFilterValue).then(
            response => {
                this.setState({
                    first,
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    openNew() {
        this.setState({
            element: this.emptyElement,
            submitted: false,
            elementDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            elementDialog: false,
            element: this.emptyElement
        });
    }

    hideDisableElementDialog() {
        this.setState({disableElementDialog: false});
    }

    confirmDisableElement(element) {
        this.setState({
            element,
            disableElementDialog: true
        });
    }

    dateTemplate(rowData) {
        if (!!rowData.initKeyDate) {
            const date = new Date(rowData.initKeyDate);
            const formattedDate = date.toLocaleDateString(this.props.locale);
            return (
                <div>{formattedDate}</div>
            );
        }
    }

    statusTemplate(rowData) {
        if (rowData) {
            return (
                <div>{I18n.t(rowData.userStatusEnum)}</div>
            );
        }
    }

    suspendElement() {
        this.setState({
            submitted: true,
        });

        if(this.state.element.nbDays===undefined){
            return;
        }

        let elements = [...this.state.elements];

        this.setState({
            loadingDialog: true,
        });

        var res = {
            accountId: this.state.element.id,
            nbDays: this.state.element.nbDays
        };

        var element = this.state.element;
        this.elementService.suspendAccount(res)
            .then((response) => {
                const index = this.findIndexById(element.id);
                console.log("response disable  ....", response.data)
                elements[index] = response.data.content;
                this.setState({
                    elements,
                    elementDialog: false,
                    loadingDialog: false,
                    element: this.emptyElement,
                });
                this.toast.show({
                    severity: 'success',
                    summary: I18n.t('UPDATE'),
                    detail: I18n.t('UPDATE_SUCCESSFUL'),
                    life: 6000
                });
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({
                    severity: 'error',
                    summary: I18n.t('UPDATE'),
                    detail: I18n.t(error.response.data.code),
                    life: 6000
                });
            });
    }


    disableElement() {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        var res = {
            accountId: this.state.element.id
        };

        var element = this.state.element;
        if (this.state.element.userStatusEnum === "USER_STATUS_ACTIVE") {
            this.elementService.disableAccount(res)
                .then((response) => {
                    const index = this.findIndexById(element.id);
                    console.log("response disable  ....", response.data)
                    elements[index] = response.data.content;
                    this.setState({
                        elements,
                        disableElementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                    });
                    this.toast.show({
                        severity: 'success',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t('UPDATE_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        } else {
            this.elementService.enableAccount(res)
                .then((response) => {
                    const index = this.findIndexById(element.id);
                    console.log("response enable  ....", response.data)

                    elements[index] = response.data.content;
                    this.setState({
                        elements,
                        disableElementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                    });
                    this.toast.show({
                        severity: 'success',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t('UPDATE_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        }
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    showSuspendElement(element) {
        this.setState({
            element: {...element},
            elementDialog: true
        });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = {...this.state.element};
        element[`${name}`] = val;
        this.setState({element});
    }

    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        let searchValue = [{field: 'name', value: searchFilterValue}];
        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue?.field, sortFilterValue?.order, searchValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({
                    severity: 'error',
                    summary: I18n.t('SEARCH'),
                    detail: I18n.t('SEARCH_NOT_IMPLEMENTED'),
                    life: 6000
                });
            });
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{I18n.t('USERS')}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue}
                               onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')}
                               placeholder={I18n.t('SEARCH_DATA')}/>
                </span>
            </div>
        );
        const elementDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times"
                        className="p-button-text" onClick={this.hideDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('SUSPEND')} icon="pi pi-check"
                        className="p-button-text" onClick={this.suspendElement}/>
            </React.Fragment>
        );
        const disableElementDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times"
                        className="p-button-text" onClick={this.hideDisableElementDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check"
                        className="p-button-text" onClick={this.disableElement}/>
            </React.Fragment>
        );

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <ContextMenu model={this.state.menuModel} ref={el => this.cm = el}
                             onHide={() => this.setState({selectedElement: null})}/>
                <div className="card">

                    <DataTable ref={(el) => this.dt = el} value={this.state.elements}
                               selection={this.state.selectedElement}
                               onSelectionChange={(e) => this.setState({selectedElement: e.value})}
                               dataKey="id" paginator lazy loading={this.state.loading} responsive
                               contextMenuSelection={this.state.selectedElement}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                               onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                               rows={this.state.pageSize} totalRecords={this.state.totalRecords}
                               first={this.state.first} onPage={this.onPage}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                               header={header}>

                        <Column selectionMode="single" headerStyle={{width: '3rem'}}></Column>
                        <Column field="lastName" header={I18n.t('LAST_NAME')}></Column>
                        <Column field="firstName" header={I18n.t('FIRST_NAME')}></Column>
                        <Column field="username" headerStyle={{width: '20rem'}} header={I18n.t('USERNAME')}/>
                        <Column field="email" headerStyle={{width: '20rem'}} header={I18n.t('EMAIL')}></Column>
                        <Column field="phoneNumber" header={I18n.t('PHONE_NUMBER')}></Column>
                        <Column field="whatsappNumber" header={I18n.t('WHATSAPP_NUMBER')}></Column>
                        <Column body={this.statusTemplate} header={I18n.t('STATUS')}></Column>
                        <Column body={this.dateTemplate} header={I18n.t('ACCOUNT_KEY_DATE')}></Column>
                    </DataTable>
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '300px'}} header={I18n.t('DETAILS')} modal
                        className="p-fluid" footer={elementDialogFooter} closable={false} onHide={this.hideDialog}>
                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            type={"number"}
                            required
                            autoFocus
                            name="nbDays"
                            value={this.state.element.nbDays}
                            onChange={(e) => this.onInputChange(e, 'nbDays')}
                            id="nbDays"
                            className={classNames({'p-invalid': this.state.submitted && !this.state.element.nbDays})}
                        />
                        {this.state.submitted && !this.state.element.nbDays &&
                            <small className="p-error">{I18n.t('NUMBER_OF_DAY_IS_REQUIRED')}</small>}
                        <label htmlFor="nbDays">{I18n.t('NUMBER_OF_DAY')}</label>
                    </div>
                </Dialog>

                <Dialog visible={this.state.disableElementDialog} style={{width: '450px'}}
                        header={I18n.t('CONFIRMATION')} modal footer={disableElementDialogFooter} closable={false}
                        onHide={this.hideDisableElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '1rem'}}/>
                        {this.state.element && this.state.element.userStatusEnum === "USER_STATUS_ACTIVE" &&
                            <Translate dangerousHTML value="DISABLE_ONE_ACCOUNT_WARNING"
                                       name={this.state.element.firstName}/>}
                        {this.state.element && this.state.element.userStatusEnum !== "USER_STATUS_ACTIVE" &&
                            <Translate dangerousHTML value="ENABLE_ONE_ACCOUNT_WARNING"
                                       name={this.state.element.firstName}/>}
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(Account);


