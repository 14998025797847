import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {FileUpload} from 'primereact/fileupload';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {I18n, Translate} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Dropdown} from "primereact/dropdown";
import {Order} from "../helpers/order";
import {connect} from "react-redux";
import * as Yup from 'yup';
import {Field, FieldArray, Formik} from 'formik';
import TemplateGroupService from "../services/TemplateGroupService";
import {extension, getFormErrorMessage, isFormFieldInvalid, toBackHashMap, toFrontHasMap} from "../helpers/utils";
import {InputTextarea} from "primereact/inputtextarea";
import RuleService from "../services/RuleService";
import {Tag} from "primereact/tag";
import InputText2 from "./select-map/InputText";
import Dropdown2 from "./select-map/Dropdown";
import FileUpload2 from "./select-map/FileUpload";
import FileService from "../services/FileService";
import fileDownload from "js-file-download";
import DocViewer2 from "./DocViewer2";

class TemplateGroup extends Component {
    emptyElement = {
        name: '',
        description: '',
        ruleActionTypeEnum: '',
        countryCode: '',
        parameters: [],
        files: []
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loadingFile: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            deleteElementDialog: false,
            deleteElementsDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            submitted: false,
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null,
            parameters: [],
        };

        this.formikProps = {
            initialValues: this.emptyElement
        };

        this.elementService = new TemplateGroupService();
        this.ruleService = new RuleService();
        this.fileService = new FileService();
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelectedElements = this.deleteSelectedElements.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.onFileSelect = this.onFileSelect.bind(this);
        this.onFileClear = this.onFileClear.bind(this);
        this.imageBodyTemplate = this.imageBodyTemplate.bind(this);
        this.loadRuleActionTypes = this.loadRuleActionTypes.bind(this);
        this.loadNotificationGroupParametersTypes = this.loadNotificationGroupParametersTypes.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
        this.previewDocument = this.previewDocument.bind(this);
        this.renderFooterPreview = this.renderFooterPreview.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
    }

    componentDidMount() {
        this.loadRuleActionTypes();
        this.loadNotificationGroupParametersTypes();
        this.createOptions();
        this.setState({loading: true});
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order).then(
            response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
            this.loadNotificationGroupParametersTypes();
        }
    }

    loadRuleActionTypes() {
        this.ruleService.getRuleActionTypes().then(
            response => {
                let ruleActionTypes = []
                if (response.data.content) {
                    for (let i = 0; i < response.data.content.length; i++) {
                        let type = response.data.content[i];
                        ruleActionTypes.push({name: type, code: type})
                    }
                }
                this.setState({ruleActionTypes})
            }
        );
    }

    loadNotificationGroupParametersTypes() {
        this.elementService.getParameterTypes().then(
            response => {
                let notificationParametersTypes = []
                if (response.data.content) {
                    for (let i = 0; i < response.data.content.length; i++) {
                        let type = response.data.content[i];
                        notificationParametersTypes.push({name: I18n.t(type), code: type})
                    }
                }
                this.setState({notificationParametersTypes})
            }
        );
    }

    createOptions() {
        const menuModel = [
            {label: I18n.t('EDIT_DATA'), icon: 'pi pi-fw pi-pencil', command: () => this.editElement(this.state.selectedElement)},
            {label: I18n.t('DELETE_DATA'), icon: 'pi pi-fw pi-trash', command: () => this.confirmDeleteElement(this.state.selectedElement)}
        ];

        const sortFilterValues = [
            {name: I18n.t('NAME') + ' ' + I18n.t('ASCENDING'), field: 'name', order: Order.ASC},
            {name: I18n.t('NAME') + ' ' + I18n.t('DESCENDING'), field: 'name', order: Order.DESC}
        ];
        this.setState(({
            menuModel,
            sortFilterValues
        }));
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        let searchFilterValue = [{field: 'name', value: this.state.searchFilterValue}];
        this.elementService.getPage(page, rows, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order, searchFilterValue).then(
            response => {
                this.setState({
                    first,
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    openNew() {
        this.setState({
            element: this.emptyElement,
            submitted: false,
            elementDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            elementDialog: false,
            previewDialog: false,
            element: this.emptyElement
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    previewDocument(input) {
        this.setState({previewDialog: true, file: input});
    }

    downloadDocument(input) {
        this.setState({loadingFile: true});
        this.fileService.downloadDocument(input.identifier)
            .then((response) => {
                fileDownload(response.data, input.name);
                this.setState({
                    loadingFile: false,
                });
            })
            .catch(err => {
                this.setState({
                    loadingFile: false,
                });
                this.toast.show({severity: 'error', summary: I18n.t('DOWNLOAD'), detail: err.response.data.message, life: 6000});
            })
    }

    saveElement = (element, {setStatus, setErrors}) => {
        const data = new FormData();
        let files = element.files;

        if (files) {
            for (let i = 0; i < files.length; i++) {
                let item = files[i];
                if (!item.identifier) {
                    data.append("files", item.file, item.name + '.' + extension(item.file.name));
                }
            }
        }

        let copyElement = {...element};
        copyElement.parameters = toBackHashMap(copyElement.parameters)
        data.append("data", JSON.stringify(copyElement));

        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        if (element.id) { //may be update
            this.elementService.edit(element.id, data)
                .then((response) => {
                    const index = this.findIndexById(element.id);
                    elements[index] = response.data.content;
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('UPDATE'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        } else { //may be creation
            this.elementService.add(data)
                .then((response) => {
                    elements.unshift(response.data.content);
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                        totalRecords: this.state.totalRecords + 1,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        }
    }

    editElement(element) {
        element.parameters = toFrontHasMap(element.parameters)
        this.formikProps.initialValues = element;
        this.setState({
            elementDialog: true
        });
    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(() => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                let selectedElements = this.state.selectedElements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords - 1,
                    loadingDialog: false,
                    selectedElements
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    deleteSelectedElements() {
        this.setState({
            loadingDialog: true,
        });
        let dataIds = this.state.selectedElements.map((el) => el.id);
        this.elementService.deleteMany(dataIds)
            .then(() => {
                let elements = this.state.elements.filter(val => !this.state.selectedElements.includes(val));
                this.setState({
                    totalRecords: this.state.totalRecords - this.state.selectedElements.length,
                    elements,
                    deleteElementsDialog: false,
                    selectedElements: [],
                    loadingDialog: false,
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementsDialog: false,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = {...this.state.element};
        element[`${name}`] = val;
        this.setState({element});
    }


    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        let searchValue = [{field: 'name', value: searchFilterValue}];
        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue?.field, sortFilterValue?.order, searchValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('SEARCH'), detail: I18n.t('SEARCH_NOT_IMPLEMENTED'), life: 6000});
            });
    }

    onFileSelect(event, props, name) {
        props.setFieldValue(name, event.files[0])
    }

    onFileClear(props, name) {
        props.setFieldValue(name, null)
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew}/>
                <Button type="button" label={I18n.t('DELETE_DATA')} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedElements.length}/>
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label={I18n.t('IMPORT_DATA')} chooseLabel="Import" className="p-mr-2 p-d-inline-block"/>
                <Button type="button" label={I18n.t('EXPORT_DATA')} icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV}/>
            </React.Fragment>
        )
    }

    imageBodyTemplate(rowData) {
        return <img src={rowData.image.url} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image.name} className="product-image"/>
    }

    renderFooterPreview() {
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('CLOSE')} icon="pi pi-times" className="p-button-text" onClick={() => this.setState({previewDialog: false})}/>
            </React.Fragment>
        );
    }

    renderFooter() {
        return (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button type="submit" form="formElementDialog" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
            </React.Fragment>
        );
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{I18n.t('TEMPLATE_GROUPS')}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue} onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')} placeholder={I18n.t('SEARCH_DATA')}/>
                 <Dropdown style={{width: '200px', marginLeft: '7px'}} value={this.state.sortFilterValue} onChange={(e) => this.onGlobalFilterChange(e, 'sortFilter')}
                           options={this.state.sortFilterValues} optionLabel="name" placeholder={I18n.t('SELECT_ORDER')}/>
                </span>
            </div>
        );
        const deleteElementDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>
        );
        const deleteElementsDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementsDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedElements}/>
            </React.Fragment>
        );

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <ContextMenu model={this.state.menuModel} ref={el => this.cm = el} onHide={() => this.setState({selectedElement: null})}/>
                <div className="card">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}/>

                    <DataTable ref={(el) => this.dt = el} value={this.state.elements} selection={this.state.selectedElements} onSelectionChange={(e) => this.setState({selectedElements: e.value})}
                               dataKey="id" paginator lazy loading={this.state.loading} responsive
                               contextMenuSelection={this.state.selectedElement} onContextMenu={e => this.cm.show(e.originalEvent)}
                               onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                               rows={this.state.pageSize} totalRecords={this.state.totalRecords} first={this.state.first} onPage={this.onPage}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                               header={header}>

                        <Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>
                        <Column field="name" header={I18n.t('NAME')}/>
                        <Column field="id" header={I18n.t('ID')}/>
                        <Column field="ruleActionTypeEnum" header={I18n.t('RULE_ACTION_TYPE')}/>
                        <Column field="countryCode" header={I18n.t('DEFAULT_LANGUAGE')}/>
                    </DataTable>
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '450px'}} footer={this.renderFooter} header={I18n.t('DETAILS')} modal className="p-fluid" closable={false} onHide={this.hideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikProps.initialValues}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                            parameters: Yup.array().of(
                                Yup.object().shape({
                                    key: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                                    value: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                                })
                            ),
                            files: Yup.array().of(
                                Yup.object().shape({
                                    name: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                                    file: Yup.mixed().when("identifier", {
                                        is: (identifier) => !!!identifier,
                                        then: (file) => file.required(I18n.t('FIELD_IS_REQUIRED'))
                                    })
                                })
                            ),
                            ruleActionTypeEnum: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                            description: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                            countryCode: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                        })}
                        onSubmit={this.saveElement}
                    >
                        {props => {
                            console.log(props)
                            return <form id="formElementDialog" onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        autoFocus
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        id="name"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "name")})}
                                    />
                                    {getFormErrorMessage(props, 'name')}<label htmlFor="name">{I18n.t('NAME')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputTextarea
                                        name="description"
                                        value={props.values.description}
                                        onChange={props.handleChange}
                                        id="description"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "description")})}
                                    />
                                    {getFormErrorMessage(props, 'description')}<label htmlFor="description">{I18n.t('DESCRIPTION')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <Dropdown
                                        name="ruleActionTypeEnum"
                                        id="ruleActionTypeEnum"
                                        value={props.values.ruleActionTypeEnum}
                                        options={this.state.ruleActionTypes}
                                        onChange={(event) => props.setFieldValue('ruleActionTypeEnum', event.value)}
                                        optionLabel="name"
                                        optionValue="code"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'ruleActionTypeEnum')})}
                                    />
                                    {getFormErrorMessage(props, 'ruleActionTypeEnum')}
                                    <label htmlFor="ruleActionTypeEnum" className={classNames({'p-error': isFormFieldInvalid(props, 'ruleActionTypeEnum')})}>{I18n.t('RULE_ACTION_TYPE')}*</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="countryCode"
                                        value={props.values.countryCode}
                                        onChange={props.handleChange}
                                        id="countryCode"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "countryCode")})}
                                    />
                                    {getFormErrorMessage(props, 'countryCode')}<label htmlFor="countryCode">{I18n.t('DEFAULT_LANGUAGE')}</label>
                                </div>

                                <FieldArray
                                    name="parameters"
                                    render={(arrayHelpers) => {
                                        const parameters = props.values.parameters;
                                        return (
                                            <>
                                        <span>
                                            <Tag style={{fontSize: '1em'}} className="p-mr-0" value={I18n.t("PARAMETERS")}/>
                                            <Button type="button" icon="pi pi-plus" className="p-button-sm p-button-text" onClick={() =>
                                                arrayHelpers.push({
                                                    key: "",
                                                    value: ""
                                                })
                                            }/>
                                        </span>
                                                {parameters && parameters.length > 0
                                                    ? parameters.map((input, index) => (
                                                        <div className="p-fluid p-grid p-formgrid" key={index}>
                                                            <div className="p-float-label p-field p-col-12 p-lg-5" style={{marginTop: '10px'}}>
                                                                <Field
                                                                    autoFocus
                                                                    label={I18n.t("TYPE")}
                                                                    id={`parameters.${index}.key`}
                                                                    name={`parameters.${index}.key`}
                                                                    options={this.state.notificationParametersTypes}
                                                                    optionLabel="name"
                                                                    optionValue="code"
                                                                    component={Dropdown2}
                                                                />
                                                            </div>
                                                            <div className="p-float-label p-field p-col-12 p-lg-5" style={{marginTop: '10px'}}>
                                                                <Field
                                                                    label={I18n.t("VALUE")}
                                                                    id={`parameters.${index}.value`}
                                                                    name={`parameters.${index}.value`}
                                                                    component={InputText2}
                                                                />
                                                            </div>
                                                            <div className="p-float-label p-field p-col-12 p-lg-2" style={{marginTop: '10px'}}>
                                                                <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))
                                                    : null}
                                            </>
                                        );
                                    }}
                                />
                                <br/>
                                <FieldArray
                                    name="files"
                                    render={(arrayHelpers) => {
                                        const files = props.values.files;
                                        return (
                                            <>
                                        <span>
                                            <Tag style={{fontSize: '1em'}} className="p-mr-0" value={I18n.t("FILES")}/>
                                            <Button type="button" icon="pi pi-plus" className="p-button-sm p-button-text" onClick={() =>
                                                arrayHelpers.push({
                                                    name: "",
                                                    file: ""
                                                })
                                            }/>
                                        </span>
                                                {files && files.length > 0
                                                    ? files.map((input, index) => (
                                                        <div key={index} className="card">
                                                            <div className="p-fluid p-grid p-formgrid">
                                                                <div className="p-float-label p-field p-col-12 p-lg-10" style={{marginTop: '10px'}}>
                                                                    <div className="p-float-label p-field p-col-12" style={{marginTop: '10px'}}>
                                                                        <Field
                                                                            autoFocus
                                                                            label={I18n.t("NAME")}
                                                                            id={`files.${index}.name`}
                                                                            name={`files.${index}.name`}
                                                                            component={InputText2}
                                                                        />
                                                                    </div>
                                                                    {!!input.identifier ?
                                                                        <div className="p-fluid p-grid p-formgrid">
                                                                            <div className="p-float-label p-field p-col-6">
                                                                                <Button label={I18n.t('VIEW')}
                                                                                        icon="pi pi-eye"
                                                                                        className="p-button-sm"
                                                                                        type="button"
                                                                                        onClick={() => this.previewDocument(input)}
                                                                                />
                                                                            </div>
                                                                            <div className="p-float-label p-field p-col-6">
                                                                                <Button onClick={() => this.downloadDocument(input)}
                                                                                        loading={this.state.loadingFile}
                                                                                        label={I18n.t('DOWNLOAD')}
                                                                                        icon="pi pi-download"
                                                                                        className="p-button-sm"
                                                                                        type="button"
                                                                                />
                                                                            </div>
                                                                        </div> :
                                                                        <div className="p-float-label p-field p-col-12" style={{marginTop: '10px'}}>
                                                                            <Field
                                                                                id={`files.${index}.file`}
                                                                                name={`files.${index}.file`}
                                                                                chooseLabel={I18n.t('CHOOSE')}
                                                                                uploadLabel={I18n.t('UPLOAD')}
                                                                                cancelLabel={I18n.t('CANCEL')}
                                                                                component={FileUpload2}
                                                                                customUpload
                                                                                onSelect={e => this.onFileSelect(e, props, `files.${index}.file`)}
                                                                                onClear={() => this.onFileClear(props, `files.${index}.file`)}
                                                                                onRemove={() => this.onFileClear(props, `files.${index}.file`)}
                                                                                maxFileSize={100000000}
                                                                            />
                                                                        </div>}
                                                                </div>
                                                                <div className="p-float-label p-field p-col-12 p-lg-2" style={{marginTop: '10px'}}>
                                                                    <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : null}
                                            </>
                                        );
                                    }}
                                />
                            </form>
                        }}
                    </Formik>
                </Dialog>

                <Dialog visible={this.state.previewDialog} style={{width: '950px'}} header={I18n.t('PREVIEW')} footer={this.renderFooterPreview} modal className="p-fluid" closable={false} onHide={() => this.setState({previewDialog: false})}>
                    {this.state.file &&
                    <DocViewer2 file={this.state.file}/>}
                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.nameFr}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementsDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementsDialogFooter} closable={false} onHide={this.hideDeleteElementsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.selectedElements.length && <Translate dangerousHTML value="DELETE_MANY_WARNING" count={this.state.selectedElements.length}/>}
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    const accessToken = state.auth.account.accessToken;
    return {
        locale,
        accessToken
    };
}

export default connect(mapStateToProps)(TemplateGroup);


